import Activity from '@client-shared/models/activity.model.js'

import filterFunctions from './filter-functions'
import generateItemSearchIndex from './generate-item-search-index'
import isOnLocalhost from './is-on-localhost.js'
import { getParticipantNameSearchSegments, performSearch } from './search-helpers'
import constants from '../config/constants.js'
import ClipboardFile from '../models/clipboard-file.model.js'
import Document from '../models/document.model.js'
import ParticipantVirtual from '../models/participant-virtual.model.js'
import Participant from '../models/participant.model.js'
import Plan from '../models/plan.model.js'
import Project from '../models/project.model.js'
import Task from '../models/task.model.js'

import i18nInstance from '@/plugins/i18n'

const $i18n = i18nInstance.global

export default {
  items: {
    matchesFilterText: ({ searchString = '', project, item }) => {
      if (searchString === '') {
        return true
      }

      if (isOnLocalhost) {
        if (!project) {
          console.error('project must be passed to matchesFilterText function')
          return
        }

        if (!(item instanceof Plan) && !(item instanceof Document)) {
          console.error('Plan or Document instance have to passed to matchFilterText')
          return
        }
      }

      return performSearch({
        text: generateItemSearchIndex({ item, project }),
        searchString,
      })
    },

    matchesFilterPhasetags: ({ phasetags, item }) => {
      if (isOnLocalhost) {
        if (!(item instanceof Plan) && !(item instanceof Document)) {
          console.error('Plan instance have to passed to matchFilterPhasetags')
          return
        }

        if (!Array.isArray(phasetags)) {
          console.error('phasetags have to be an Array')
          return
        }
      }

      if (phasetags.length === 0) {
        return true
      }

      return phasetags.includes(item.phasetag)
    },

    matchesFilterAuthor: ({ authorUserIds, authorRoles, item, project }) => {
      if (authorUserIds.length === 0 && authorRoles.length === 0) {
        return true
      }

      const matchesFilterAuthorUserIds = authorUserIds.length > 0
        ? filterFunctions.items.matchesFilterAuthorUserIds({
            authorUserIds,
            item,
          })
        : false

      const matchesFilterAuthorRoles = authorRoles.length > 0
        ? filterFunctions.items.matchesFilterAuthorRoles({
            authorRoles,
            item,
            project,
          })
        : false

      return matchesFilterAuthorUserIds || matchesFilterAuthorRoles
    },

    matchesFilterPermissions: ({ permissionUserIds = [], permissions, item, project }) => {
      if (permissionUserIds.length === 0 && permissions.length === 0) {
        return true
      }

      const matchesFilterPermissionParticipantIds = permissionUserIds.length > 0
        ? filterFunctions.items.matchesFilterPermissionParticipantIds({
            permissionUserIds,
            item,
          })
        : false

      const matchesFilterDocumentPermissions = permissions.length > 0
        ? filterFunctions.items.matchesFilterDocumentPermissions({
            permissions,
            item,
            project,
          })
        : false

      return matchesFilterPermissionParticipantIds || matchesFilterDocumentPermissions
    },

    matchesFilterPermissionParticipantIds: ({ permissionUserIds, item }) => {
      if (isOnLocalhost) {
        if (!(item instanceof Plan) && !(item instanceof Document)) {
          console.error('Plan or Document instance have to passed to matchFilterPermissionUserIds')
          return
        }

        if (!Array.isArray(permissionUserIds)) {
          console.error('ids have to be an Array')
          return
        }
      }

      if (permissionUserIds.length === 0) {
        return true
      }

      return permissionUserIds.some(id => item.permission.users.includes(id))
    },

    matchesFilterDocumentPermissions: ({ permissions, item }) => {
      if (isOnLocalhost) {
        if (!Array.isArray(permissions)) {
          console.error('permissions have to be an Array')
          return
        }

        if (!item) {
          console.error('item must be passed to permissionFilter query')
          return
        }
      }

      if (permissions.length === 0) {
        return true
      }

      const showPublic = permissions.includes(constants.DOCUMENT_PERMISSIONS.PUBLIC)
      const showRestricted = permissions.includes(constants.DOCUMENT_PERMISSIONS.RESTRICTED)

      if (showPublic && showRestricted) {
        return true
      }

      if (showPublic) {
        return item.permission.restricted === false
      }

      if (showRestricted) {
        return item.permission.restricted === true
      }

      return false
    },

    matchesFilterApprover: ({ approverUserIds, approverRoles, item, project }) => {
      if (approverUserIds.length === 0 && approverRoles.length === 0) {
        return true
      }

      const matchesFilterApproverUserIds = approverUserIds.length > 0
        ? filterFunctions.items.matchesFilterApproverUserIds({
            project,
            approverUserIds,
            item,
          })
        : false

      const matchesFilterApproverRoles = approverRoles.length > 0
        ? filterFunctions.items.matchesFilterApproverRoles({
            approverRoles,
            item,
            project,
          })
        : false

      return matchesFilterApproverUserIds || matchesFilterApproverRoles
    },

    matchesFilterAuthorUserIds: ({ authorUserIds, item }) => {
      if (isOnLocalhost) {
        if (!(item instanceof Plan) && !(item instanceof Document)) {
          console.error('Plan or Document instance have to passed to matchFilterRoles')
          return
        }

        if (!Array.isArray(authorUserIds)) {
          console.error('roles have to be an Array')
          return
        }
      }

      if (authorUserIds.length === 0) {
        return true
      }

      return authorUserIds.includes(item.author.userId)
    },

    matchesFilterAuthorRoles: ({ project, authorRoles, item }) => {
      if (isOnLocalhost) {
        if (!(item instanceof Plan) && !(item instanceof Document)) {
          console.error('Plan or Document instance have to passed to matchFilterRoles')
          return
        }

        if (!(project instanceof Project)) {
          console.error('Project instance have to passed to matchesFilterCreatorRoles')
          return
        }

        if (!Array.isArray(authorRoles)) {
          console.error('creatorRoles have to be an Array')
          return
        }
      }

      if (authorRoles.length === 0) {
        return true
      }

      if (item.author.userId) {
        const itemAuthorParticipant = project.findParticipantByUserId(item.author.userId)
        return authorRoles.includes(itemAuthorParticipant.roles[0])
      }

      if (item.author.role) {
        return authorRoles.includes(item.author.role)
      }

      return true
    },

    matchesFilterApproverUserIds: ({ approverUserIds, item, project }) => {
      if (isOnLocalhost) {
        if (!(item instanceof Plan) && !(item instanceof Document)) {
          console.error('Plan or Document instance have to passed to matchesFilterApproverUserIds')
          return
        }

        if (!(project instanceof Project)) {
          console.error('Project instance have to passed to matchesFilterApproverUserIds')
          return
        }

        if (!Array.isArray(approverUserIds)) {
          console.error('roles have to be an Array')
          return
        }
      }

      if (approverUserIds.length === 0) {
        return true
      }

      const latestRevision = item.revisions[item.revisions.length - 1]

      if (!latestRevision.approval || latestRevision.approval.by.length === 0) {
        return false
      }

      const approvalByMatch = latestRevision.approval.by.find(approvalBy => {
        const participant = project.participants.find(p => p._id === approvalBy.participantid)
        const userId = participant.user._id || participant.user

        return approverUserIds.includes(userId)
      })

      return Boolean(approvalByMatch)
    },

    matchesFilterApproverRoles: ({ project, approverRoles, item }) => {
      if (isOnLocalhost) {
        if (!(item instanceof Plan) && !(item instanceof Document)) {
          console.error('Plan or Document instance have to passed to matchesFilterApproverRoles')
          return
        }

        if (!(project instanceof Project)) {
          console.error('Project instance have to passed to matchesFilterApproverRoles')
          return
        }

        if (!Array.isArray(approverRoles)) {
          console.error('creatorRoles have to be an Array')
          return
        }
      }

      if (approverRoles.length === 0) {
        return true
      }

      const latestRevision = item.revisions[item.revisions.length - 1]

      if (!latestRevision.approval || latestRevision.approval.by.length === 0) {
        return false
      }

      const approvalByMatch = latestRevision.approval.by.find(approvalBy => {
        const participant = project.participants.find(p => p._id === approvalBy.participantid)
        const userId = participant.roles[0]

        return approverRoles.includes(userId)
      })

      return Boolean(approvalByMatch)
    },

    matchesFilterTags: ({ tags, tagsAndSwitchActive, item }) => {
      if (isOnLocalhost) {
        if (!(item instanceof Plan) && !(item instanceof Document)) {
          console.error('Plan instance have to passed to matchFilterTags')
          return
        }

        if (!Array.isArray(tags)) {
          console.error('tags have to be an Array')
          return
        }

        if (tagsAndSwitchActive !== true && tagsAndSwitchActive !== false) {
          console.error('tagsAndSwitchActive must be passed to tagFilter query')
          return
        }
      }

      if (tags.length === 0) {
        return true
      }

      let isMatch

      if (tagsAndSwitchActive) {
        isMatch = tags.every(tag => item.tags.includes(tag))
      } else {
        isMatch = tags.some(tag => item.tags.includes(tag))
      }

      return isMatch
    },

    matchesFilterApprovals: ({ approvals, latestRevision }) => {
      if (isOnLocalhost) {
        if (!Array.isArray(approvals)) {
          console.error('approvals have to be an Array')
          return
        }

        if (!latestRevision) {
          console.error('latestRevision must be passed to approvalFilter query')
          return
        }
      }

      if (approvals.length === 0) {
        return true
      }

      // If anybody rejected an approval, the plan will be shown in the "rejected" filter, although the plan still can be pending
      if (approvals.includes('rejected') && latestRevision.approval && latestRevision.approval.by) {
        const hasRejectedApprovers = latestRevision.approval.by.find(approver => approver.approved === 'rejected')

        if (hasRejectedApprovers) {
          return true
        }
      }

      if (approvals.includes('approved') && latestRevision.approvalStatus === 'approvedViaImport') {
        return true
      }

      return approvals.includes(latestRevision.approvalStatus)
    },

    matchesFilterTopCategory: ({ topCategories, item }) => {
      if (!topCategories) { return }

      if (isOnLocalhost) {
        if (!(item instanceof Plan) && !(item instanceof Document)) {
          console.error('Plan instance have to passed to matchesFilterTopCategory')
          return
        }

        if (!Array.isArray(topCategories)) {
          console.error('categories have to be an Array')
          return
        }
      }

      if (topCategories.length === 0) {
        return true
      }

      return topCategories.includes(item.topCategory)
    },

    matchesFilterApprovalDateFrom: ({ approvalDateFrom, item }) => {
      if (isOnLocalhost) {
        if (!(item instanceof Plan) && !(item instanceof Document)) {
          console.error('Plan instance have to passed to matchFilterTags')
          return
        }
      }

      if (!approvalDateFrom) {
        return true
      }

      const latestRevision = item.revisions[item.revisions.length - 1]

      if (!latestRevision.approval.approvalDate) {
        return false
      }

      return new Date(latestRevision.approval.approvalDate) >= new Date(approvalDateFrom)
    },

    matchesFilterApprovalDateTo: ({ approvalDateTo, item }) => {
      if (isOnLocalhost) {
        if (!(item instanceof Plan) && !(item instanceof Document)) {
          console.error('Plan instance have to passed to matchFilterTags')
          return
        }
      }

      if (!approvalDateTo) {
        return true
      }

      const latestRevision = item.revisions[item.revisions.length - 1]

      if (!latestRevision.approval.approvalDate) {
        return false
      }

      return new Date(latestRevision.approval.approvalDate) <= new Date(approvalDateTo)
    },

    matchesFilterApprovalDatePreset: ({ approvalDatePreset, item }) => {
      if (isOnLocalhost) {
        if (!(item instanceof Plan) && !(item instanceof Document)) {
          console.error('Plan instance have to passed to matchFilterTags')
          return
        }
      }

      if (!approvalDatePreset) {
        return true
      }

      const latestRevision = item.revisions[item.revisions.length - 1]

      if (approvalDatePreset === constants.VALID_ITEM_APPROVAL_DATE_PRESETS.NOT_SET) {
        return !latestRevision.approval.approvalDate
      }

      if (!latestRevision.approval.approvalDate) {
        return false
      }

      if (approvalDatePreset === constants.VALID_ITEM_APPROVAL_DATE_PRESETS.OVERDUE) {
        return latestRevision.approvalStatus === 'pending' && new Date(latestRevision.approval.approvalDate) <= new Date()
      }

      return true
    },
  },

  plans: {
    matchesFilters: ({ item, project, searchString, phasetags, authorUserIds, authorRoles, approverUserIds, approverRoles, tags, tagsAndSwitchActive, approvals, topCategories, approvalDateFrom, approvalDateTo, approvalDatePreset }) => {
      if (isOnLocalhost) {
        if (!item) {
          console.error('item must be passed to matchesFilter function')
          return
        }

        if (!project) {
          console.error('project must be passed to matchesFilter function')
          return
        }
      }

      const matchTextSearch = filterFunctions.items.matchesFilterText({
        searchString,
        project,
        item,
      })
      if (!matchTextSearch) { return false }

      const matchApprovalFilter = filterFunctions.items.matchesFilterApprovals({
        approvals,
        latestRevision: item.computed.latestRevision,
        item,
      })
      if (!matchApprovalFilter) { return false }

      const matchApproverFilter = filterFunctions.items.matchesFilterApprover({
        approverUserIds,
        approverRoles,
        item,
        project,
      })
      if (!matchApproverFilter) { return false }

      const matchPhasetagsFilter = filterFunctions.items.matchesFilterPhasetags({
        phasetags,
        item,
      })
      if (!matchPhasetagsFilter) { return false }

      const matchTagsFilter = filterFunctions.items.matchesFilterTags({
        tags,
        tagsAndSwitchActive,
        item,
      })
      if (!matchTagsFilter) { return false }

      const matchAuthorFilter = filterFunctions.items.matchesFilterAuthor({
        authorUserIds,
        authorRoles,
        item,
        project,
      })
      if (!matchAuthorFilter) { return false }

      const matchesTopCategoryFilter = filterFunctions.items.matchesFilterTopCategory({
        topCategories,
        item,
      })

      if (!matchesTopCategoryFilter) { return false }

      const matchApprovalDateFromFilter = filterFunctions.items.matchesFilterApprovalDateFrom({
        approvalDateFrom,
        item,
      })
      if (!matchApprovalDateFromFilter) { return false }

      const matchApprovalDateToFilter = filterFunctions.items.matchesFilterApprovalDateTo({
        approvalDateTo,
        item,
      })
      if (!matchApprovalDateToFilter) { return false }

      const matchApprovalDatePresetFilter = filterFunctions.items.matchesFilterApprovalDatePreset({
        approvalDatePreset,
        item,
      })
      if (!matchApprovalDatePresetFilter) { return false }

      return true
    },
  },

  documents: {
    matchesFilters: ({ item, project, searchString, phasetags, authorUserIds, authorRoles, approverUserIds, approverRoles, tags, tagsAndSwitchActive, approvals, permissions, permissionUserIds, topCategories, approvalDateFrom, approvalDateTo, approvalDatePreset }) => {
      if (isOnLocalhost) {
        if (!item) {
          console.error('item must be passed to matchesFilter function')
          return
        }

        if (!project) {
          console.error('project must be passed to matchesFilter function')
          return
        }
      }

      const matchTextSearch = filterFunctions.items.matchesFilterText({
        searchString,
        project,
        item,
      })
      if (!matchTextSearch) { return false }

      const matchApprovalFilter = filterFunctions.items.matchesFilterApprovals({
        approvals,
        latestRevision: item.computed.latestRevision,
        item,
      })
      if (!matchApprovalFilter) { return false }

      const matchApproverFilter = filterFunctions.items.matchesFilterApprover({
        approverUserIds,
        approverRoles,
        item,
        project,
      })
      if (!matchApproverFilter) { return false }

      const matchPhasetagsFilter = filterFunctions.items.matchesFilterPhasetags({
        phasetags,
        item,
      })
      if (!matchPhasetagsFilter) { return false }

      const matchTagsFilter = filterFunctions.items.matchesFilterTags({
        tags,
        tagsAndSwitchActive,
        item,
      })
      if (!matchTagsFilter) { return false }

      const matchAuthorFilter = filterFunctions.items.matchesFilterAuthor({
        authorUserIds,
        authorRoles,
        item,
        project,
      })
      if (!matchAuthorFilter) { return false }

      const matchPermissionsFilter = filterFunctions.items.matchesFilterPermissions({
        permissionUserIds,
        permissions,
        item,
        project,
      })
      if (!matchPermissionsFilter) { return false }

      const matchesTopCategoryFilter = filterFunctions.items.matchesFilterTopCategory({
        topCategories,
        item,
      })
      if (!matchesTopCategoryFilter) { return false }

      if (!matchesTopCategoryFilter) { return false }

      const matchApprovalDateFromFilter = filterFunctions.items.matchesFilterApprovalDateFrom({
        approvalDateFrom,
        item,
      })
      if (!matchApprovalDateFromFilter) { return false }

      const matchApprovalDateToFilter = filterFunctions.items.matchesFilterApprovalDateTo({
        approvalDateTo,
        item,
      })
      if (!matchApprovalDateToFilter) { return false }

      const matchApprovalDatePresetFilter = filterFunctions.items.matchesFilterApprovalDatePreset({
        approvalDatePreset,
        item,
      })
      if (!matchApprovalDatePresetFilter) { return false }

      return true
    },
  },

  tasks: {
    matchesFilters: ({ task, project, searchString, types, states, assigneeUserIds, assigneeRoles, creatorUserIds, creatorRoles, tags, tagsAndSwitchActive, dueDateFrom, dueDateTo, dueDatePreset, location1, location2, location3, location4, locationLayerIds }) => {
      if (isOnLocalhost) {
        if (!task) {
          console.error('task must be passed to matchesFilter function')
          return
        }

        if (!project) {
          console.error('project must be passed to matchesFilter function')
          return
        }
      }

      const matchTextSearch = filterFunctions.tasks.matchesFilterText({
        searchString,
        task,
        project,
      })
      if (!matchTextSearch) { return false }

      const matchTypesFilter = filterFunctions.tasks.matchesFilterTypes({
        types,
        task,
      })
      if (!matchTypesFilter) { return false }

      const matchStatesFilter = filterFunctions.tasks.matchesFilterStates({
        states,
        task,
      })
      if (!matchStatesFilter) { return false }

      const matchAssigneeFilter = filterFunctions.tasks.matchesFilterAssignee({
        assigneeUserIds,
        assigneeRoles,
        task,
        project,
      })
      if (!matchAssigneeFilter) { return false }

      const matchCreatorFilter = filterFunctions.tasks.matchesFilterCreator({
        creatorUserIds,
        creatorRoles,
        task,
        project,
      })
      if (!matchCreatorFilter) { return false }

      const matchDueDateFromFilter = filterFunctions.tasks.matchesFilterDueDateFrom({
        dueDateFrom,
        task,
      })
      if (!matchDueDateFromFilter) { return false }

      const matchDueDateToFilter = filterFunctions.tasks.matchesFilterDueDateTo({
        dueDateTo,
        task,
      })
      if (!matchDueDateToFilter) { return false }

      const matchDueDatePresetFilter = filterFunctions.tasks.matchesFilterDueDatePreset({
        dueDatePreset,
        task,
      })
      if (!matchDueDatePresetFilter) { return false }

      if (project.locations.location1.isEnabled) {
        const matchLocation1Filter = filterFunctions.tasks.matchesFilterLocationProperty({
          locationProperty: 'location1',
          locationValues: location1,
          task,
        })
        if (!matchLocation1Filter) { return false }
      }

      const matchTagsFilter = filterFunctions.tasks.matchesFilterTags({
        tags,
        tagsAndSwitchActive,
        task,
      })
      if (!matchTagsFilter) { return false }

      if (project.locations.location2.isEnabled) {
        const matchLocation2Filter = filterFunctions.tasks.matchesFilterLocationProperty({
          locationProperty: 'location2',
          locationValues: location2,
          task,
        })
        if (!matchLocation2Filter) { return false }
      }

      if (project.locations.location3.isEnabled) {
        const matchLocatio3Filter = filterFunctions.tasks.matchesFilterLocationProperty({
          locationProperty: 'location3',
          locationValues: location3,
          task,
        })
        if (!matchLocatio3Filter) { return false }
      }

      if (project.locations.location4.isEnabled) {
        const matchLocation4Filter = filterFunctions.tasks.matchesFilterLocationProperty({
          locationProperty: 'location4',
          locationValues: location4,
          task,
        })
        if (!matchLocation4Filter) { return false }
      }

      const matchLocationLayerIdFilter = filterFunctions.tasks.matchesFilterLocationLayerIds({
        locationLayerIds,
        task,
      })
      if (!matchLocationLayerIdFilter) { return false }

      return true
    },

    matchesFilterText: ({ searchString = '', task, project }) => {
      if (searchString === '') {
        return true
      }
      if (isOnLocalhost) {
        if (!task) {
          console.error('task must be passed to matchesFilter function')
          return
        }

        if (!project) {
          console.error('project must be passed to matchesFilter function')
          return
        }
      }

      let assigneeParticipant
      let creatorParticipant

      if (task.assigneeUserId) {
        assigneeParticipant = project.findParticipantByUserId(task.assigneeUserId)

        if (!assigneeParticipant) {
          console.error(`no participant with userId ${task.assigneeUserId} found`)
        }
      }

      if (task.creatorUserId) {
        creatorParticipant = project.findParticipantByUserId(task.creatorUserId)

        if (!creatorParticipant) {
          console.error(`no participant with userId ${task.creatorUserId} found`)
        }
      }

      const textSearchIndex = [
        task.consecutiveNumber ? `#${task.consecutiveNumber}` : null,
        task.title,
        task.location1,
        task.location2,
        task.location3,
        task.location4,
        task.type,
        ...getParticipantNameSearchSegments(creatorParticipant),
        creatorParticipant ? creatorParticipant.roles[0] : null,
        creatorParticipant ? creatorParticipant.user.email : null,
        ...getParticipantNameSearchSegments(assigneeParticipant),
        assigneeParticipant ? assigneeParticipant.roles[0] : null,
        assigneeParticipant ? assigneeParticipant.user.email : null,
        ...task.tags,
      ]
        .filter(Boolean)
        .join(constants.SEARCH_INDEX_SEPARATOR)
        .toLocaleLowerCase()

      return performSearch({
        text: textSearchIndex,
        searchString,
      })
    },

    matchesFilterTypes: ({ types, task }) => {
      if (isOnLocalhost) {
        if (!Array.isArray(types)) {
          console.error('types have to be an Array')
          return
        }
      }

      if (types.length === 0) {
        return true
      }

      if (!task.type) {
        return false
      }

      return types.includes(task.type)
    },

    matchesFilterStates: ({ states, task }) => {
      if (isOnLocalhost) {
        if (!Array.isArray(states)) {
          console.error('states have to be an Array')
          return
        }
      }

      if (states.length === 0) {
        return true
      }

      if (!task.state) {
        return false
      }

      return states.includes(task.state)
    },

    matchesFilterAssignee: ({ assigneeUserIds, assigneeRoles, task, project }) => {
      if (assigneeUserIds.length === 0 && assigneeRoles.length === 0) {
        return true
      }

      const matchesFilterAssigneeUserIds = assigneeUserIds.length > 0
        ? filterFunctions.tasks.matchesFilterAssigneeUserIds({
            assigneeUserIds,
            task,
          })
        : false

      const matchesFilterAssigneeRoles = assigneeRoles.length > 0
        ? filterFunctions.tasks.matchesFilterAssigneeRoles({
            assigneeRoles,
            task,
            project,
          })
        : false

      return matchesFilterAssigneeUserIds || matchesFilterAssigneeRoles
    },

    matchesFilterAssigneeUserIds: ({ assigneeUserIds, task }) => {
      if (isOnLocalhost) {
        if (!(task instanceof Task)) {
          console.error('Task instance have to passed to matchesFilterAssigneeUserIds')
          return
        }

        if (!Array.isArray(assigneeUserIds)) {
          console.error('roles have to be an Array')
          return
        }
      }

      if (assigneeUserIds.length === 0) {
        return true
      }

      if (!task.assigneeUserId) {
        return false
      }

      return assigneeUserIds.includes(task.assigneeUserId)
    },

    matchesFilterAssigneeRoles: ({ assigneeRoles, task, project }) => {
      if (isOnLocalhost) {
        if (!(task instanceof Task)) {
          console.error('Task instance have to passed to matchesFilterAssigneeRoles')
          return
        }

        if (!(project instanceof Project)) {
          console.error('Project instance have to passed to matchesFilterAssigneeRoles')
          return
        }

        if (!Array.isArray(assigneeRoles)) {
          console.error('assigneeRoles have to be an Array')
          return
        }
      }

      if (assigneeRoles.length === 0) {
        return true
      }

      if (!task.assigneeUserId) {
        return false
      }

      const taskAssigneeParticipant = project.findParticipantByUserId(task.assigneeUserId)

      return assigneeRoles.includes(taskAssigneeParticipant.roles[0])
    },

    matchesFilterCreator: ({ creatorUserIds, creatorRoles, task, project }) => {
      if (creatorUserIds.length === 0 && creatorRoles.length === 0) {
        return true
      }

      const matchesFilterCreatorUserIds = creatorUserIds.length > 0
        ? filterFunctions.tasks.matchesFilterCreatorUserIds({
            creatorUserIds,
            task,
          })
        : false

      const matchesFilterCreatorRoles = creatorRoles.length > 0
        ? filterFunctions.tasks.matchesFilterCreatorRoles({
            creatorRoles,
            task,
            project,
          })
        : false

      return matchesFilterCreatorUserIds || matchesFilterCreatorRoles
    },

    matchesFilterCreatorUserIds: ({ creatorUserIds, task }) => {
      if (isOnLocalhost) {
        if (!(task instanceof Task)) {
          console.error('Task instance have to passed to matchesFilterCreatorUserIds')
          return
        }

        if (!Array.isArray(creatorUserIds)) {
          console.error('roles have to be an Array')
          return
        }
      }

      if (creatorUserIds.length === 0) {
        return true
      }

      return creatorUserIds.includes(task.creatorUserId)
    },

    matchesFilterCreatorRoles: ({ creatorRoles, task, project }) => {
      if (isOnLocalhost) {
        if (!(task instanceof Task)) {
          console.error('Task instance have to passed to matchesFilterCreatorRoles')
          return
        }

        if (!(project instanceof Project)) {
          console.error('Project instance have to passed to matchesFilterCreatorRoles')
          return
        }

        if (!Array.isArray(creatorRoles)) {
          console.error('creatorRoles have to be an Array')
          return
        }
      }

      if (creatorRoles.length === 0) {
        return true
      }

      const taskCreatorParticipant = project.findParticipantByUserId(task.creatorUserId)

      return creatorRoles.includes(taskCreatorParticipant.roles[0])
    },

    matchesFilterDueDateFrom: ({ dueDateFrom, task }) => {
      if (isOnLocalhost) {
        if (!(task instanceof Task)) {
          console.error('Task instance have to be passed to matchesFilterDueDateFrom')
          return
        }
      }

      if (!dueDateFrom) {
        return true
      }

      if (!task.dueDate) {
        return false
      }

      return new Date(task.dueDate) >= new Date(dueDateFrom)
    },

    matchesFilterDueDateTo: ({ dueDateTo, task }) => {
      if (isOnLocalhost) {
        if (!(task instanceof Task)) {
          console.error('Task instance have to be passed to matchesFilterDueDateTo')
          return
        }
      }

      if (!dueDateTo) {
        return true
      }

      if (!task.dueDate) {
        return false
      }

      return new Date(task.dueDate) <= new Date(dueDateTo)
    },

    matchesFilterDueDatePreset: ({ dueDatePreset, task }) => {
      if (isOnLocalhost) {
        if (!(task instanceof Task)) {
          console.error('Task instance have to be passed to matchesFilterDueDate')
          return
        }
      }

      if (!dueDatePreset) {
        return true
      }

      if (dueDatePreset === constants.VALID_TASK_DUE_DATE_PRESETS.NOT_SET) {
        return !task.dueDate
      }

      if (!task.dueDate) {
        return false
      }

      if (dueDatePreset === constants.VALID_TASK_DUE_DATE_PRESETS.OVERDUE) {
        return task.state !== constants.VALID_TASK_STATES.DONE && new Date(task.dueDate) <= new Date()
      }

      return true
    },

    matchesFilterTags: ({ tags, tagsAndSwitchActive, task }) => {
      if (isOnLocalhost) {
        if (!(task instanceof Task)) {
          console.error('Task instance have to passed to matchFilterTags')
          return
        }

        if (!Array.isArray(tags)) {
          console.error('tags have to be an Array')
          return
        }

        if (tagsAndSwitchActive !== true && tagsAndSwitchActive !== false) {
          console.error('tagsAndSwitchActive must be passed to tagFilter query')
          return
        }
      }

      if (tags.length === 0) {
        return true
      }

      let isMatch

      if (tagsAndSwitchActive) {
        isMatch = tags.every(tag => task.tags.includes(tag))
      } else {
        isMatch = tags.some(tag => task.tags.includes(tag))
      }

      return isMatch
    },

    matchesFilterLocationProperty: ({ locationProperty, locationValues, task }) => {
      if (isOnLocalhost) {
        if (!locationProperty) {
          console.error('locationProperty must be passed to matchesFilterLocationProperty')
          return
        }

        if (!Array.isArray(locationValues)) {
          console.error('locationValues have to be an Array')
          return
        }
      }

      if (locationValues.length === 0) {
        return true
      }

      if (!task[locationProperty]) {
        return locationValues.includes(null) // Use "null", not "undefined", because "undefined" is not a valid JSON value and gets parsed to null
      }

      return locationValues.includes(task[locationProperty])
    },

    matchesFilterLocationLayerIds: ({ locationLayerIds, task }) => {
      if (isOnLocalhost) {
        if (!(task instanceof Task)) {
          console.error('Task instance have to passed to matchesFilterAssigneeRoles')
          return
        }

        if (!Array.isArray(locationLayerIds)) {
          console.error('locationLayerIds have to be an Array')
          return
        }
      }

      if (locationLayerIds.length === 0) {
        return true
      }

      if (!task.locationLayerId) {
        return locationLayerIds.includes(null) // Use "null", not "undefined", because "undefined" is not a valid JSON value and gets parsed to null
      }

      return locationLayerIds.includes(task.locationLayerId)
    },
  },

  projects: {
    matchesFilterText: ({ searchString = '', project }) => {
      if (isOnLocalhost) {
        if (!(project instanceof Project)) {
          console.error('Project instance have to passed to matchFilterText')
          return
        }
      }

      if (searchString === '') {
        return true
      }

      return performSearch({
        text: project.title,
        searchString,
      })
    },
    matchesFilterOwnership: ({ ownership, userId, project }) => {
      if (ownership === constants.FILTER_PROPERTIES.PROJECTS_OWNERSHIP_ALL) {
        return true
      }

      const { permission } = project.findParticipantByUserId(userId)

      if (ownership === constants.FILTER_PROPERTIES.PROJECTS_OWNERSHIP_OWNER && permission === 'owner') {
        return true
      }

      if (ownership === constants.FILTER_PROPERTIES.PROJECTS_OWNERSHIP_PARTICIPANT && permission !== 'owner') {
        return true
      }

      return false
    },
  },

  participants: {
    matchesFilterText: ({ searchString = '', item }) => {
      if (isOnLocalhost) {
        if (!(item instanceof Participant) && !(item instanceof ParticipantVirtual)) {
          console.error('Participant or ParticipantVirtual instance have to passed to matchFilterText')
          return
        }
      }

      if (searchString === '') {
        return true
      }

      const searchIndex = [
        ...getParticipantNameSearchSegments(item),
        item.phone,
        item.company,
        item.user ? item.user.email : undefined,
        item.roles ? item.roles[0] : undefined,
        item.address?.zip,
        item.address?.street,
        item.address?.city,
        item.address?.country ? $i18n.t(`layout.country_codes.${item.address.country}`) : undefined,
        item.note,
      ]
        .filter(Boolean)
        .join(constants.SEARCH_INDEX_SEPARATOR)
        .toLowerCase()

      return performSearch({
        text: searchIndex,
        searchString,
      })
    },

    matchesFilterTextMinimal: ({ searchString = '', item }) => {
      if (isOnLocalhost) {
        if (!(item instanceof Participant) && !(item instanceof ParticipantVirtual)) {
          console.error('Participant or ParticipantVirtual instance have to passed to matchFilterText')
          return
        }
      }

      if (searchString === '') {
        return true
      }

      const searchIndex = [
        ...getParticipantNameSearchSegments(item),
        item.user.email,
        item.roles[0],
      ]
        .filter(Boolean)
        .join(constants.SEARCH_INDEX_SEPARATOR)
        .toLowerCase()

      return performSearch({
        text: searchIndex,
        searchString,
      })
    },

    matchesFilterRoles: ({ roles, item }) => {
      if (isOnLocalhost) {
        if (!(item instanceof Participant)) {
          console.error('Participant instance have to passed to matchFilterRoles')
          return
        }

        if (!Array.isArray(roles)) {
          console.error('roles have to be an Array')
          return
        }
      }

      if (roles.length === 0) {
        return true
      }

      return roles.includes(item.roles[0])
    },

    matchesFilterPermissions: ({ permissions, item }) => {
      if (isOnLocalhost) {
        if (!(item instanceof Participant)) {
          console.error('Participant instance have to passed to matchesFilterPermissions')
          return
        }

        if (!Array.isArray(permissions)) {
          console.error('permissions have to be an Array')
          return
        }
      }

      if (permissions.length === 0) {
        return true
      }

      return permissions.includes(item.permission)
    },

    matchesFilters: ({ item, searchString, roles, permissions }) => {
      const matchTextSearch = filterFunctions.participants.matchesFilterText({
        searchString,
        item,
      })
      if (!matchTextSearch) { return false }

      const matchRolesFilter = filterFunctions.participants.matchesFilterRoles({
        roles,
        item,
      })
      if (!matchRolesFilter) { return false }

      const matchPermissionsFilter = filterFunctions.participants.matchesFilterPermissions({
        permissions,
        item,
      })
      if (!matchPermissionsFilter) { return false }

      return true
    },
  },

  clipboard: {
    matchesFilterText: ({ searchString = '', clipboardFile }) => {
      if (isOnLocalhost) {
        if (!(clipboardFile instanceof ClipboardFile)) {
          console.error('ClipboardFile instance have to passed to matchFilterText')
          return
        }
      }

      if (searchString === '') {
        return true
      }

      return performSearch({
        text: decodeURIComponent(clipboardFile.name),
        searchString,
      })
    },
  },

  activities: {
    matchesFilters: ({ item, project, categories, participantUserIds, participantRoles }) => {
      if (isOnLocalhost) {
        if (!item) {
          console.error('item must be passed to matchesFilter function')
          return
        }

        if (!project) {
          console.error('project must be passed to matchesFilter function')
          return
        }
      }

      const matchRolesFilter = filterFunctions.activities.matchesFilterRoles({
        roles: participantRoles,
        activity: item,
        project,
      })

      if (!matchRolesFilter) { return false }

      const matchParticipantsFilter = filterFunctions.activities.matchesFilterParticipant({
        participantUserIds,
        activity: item,
        project,
      })

      if (!matchParticipantsFilter) { return false }

      const matchCategoriesFilter = filterFunctions.activities.matchesFilterCategories({
        categories,
        activity: item,
      })

      if (!matchCategoriesFilter) { return false }

      return true
    },

    matchesFilterCategories: ({ categories, activity }) => {
      if (isOnLocalhost) {
        if (!Array.isArray(categories)) {
          console.error('Categories have to be an Array')
          return
        }
      }

      if (categories.length === 0) {
        return true
      }

      if (!activity.type) {
        return false
      }

      return categories.includes(activity.action)
    },

    matchesFilterRoles: ({ roles, activity, project }) => {
      if (isOnLocalhost) {
        if (!(activity instanceof Activity)) {
          console.error('activity instance have to passed to matchFilterRoles')
          return
        }

        if (!project) {
          console.error('project must be passed to matchFilterRoles function')
          return
        }

        if (!Array.isArray(roles)) {
          console.error('roles have to be an Array')
          return
        }
      }

      if (roles.length === 0) {
        return true
      }

      const participant = project.findParticipantByUserId(activity.user)

      return participant.roles.filter((role) => roles.includes(role))
    },

    matchesFilterParticipant: ({ participantUserIds, activity, project }) => {
      if (isOnLocalhost) {
        if (!(activity instanceof Activity)) {
          console.error('activity instance have to passed to matchFilterRoles')
          return
        }

        if (!project) {
          console.error('project must be passed to matchFilterRoles function')
          return
        }

        if (!Array.isArray(participantUserIds)) {
          console.error('participantUserIds have to be an Array')
          return
        }
      }

      if (participantUserIds.length === 0) {
        return true
      }

      return participantUserIds.includes(activity.user)
    },
  },
}
