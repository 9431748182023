// local
// import runtimeConfig from '@runtime-config'
import isOnLocalhost from '@client-shared/utils/is-on-localhost'
import { reactive } from 'vue'

import packageJson from '../../package.json'

// app.planfred.com
import configAppApgPlanserverAt from '@/config/hosts/app.apg-planserver.at.js'
import configAppEgvPlanserverDe from '@/config/hosts/app.egv-planserver.de.js'
import configAppKlgPlanserverDe from '@/config/hosts/app.klg-planserver.de.js'
import configAppKoupserverAt from '@/config/hosts/app.koupserver.at.js'
import configAppPlanfredCom from '@/config/hosts/app.planfred.com.js'
// app.planfred.com.planfredapp.com
import configAppPlanfredComPlanfredappCom from '@/config/hosts/app.planfred.com.planfredapp.com.js'
// demo server
import configAppStaabserverCom from '@/config/hosts/app.staabserver.com.js'
import configDemoPlanfredCom from '@/config/hosts/demo.planfred.com.js'
import configEditDemoPlanfredCom from '@/config/hosts/edit.demo.planfred.com.js'
// enterprise customers
import configLocalhostTest from '@/config/hosts/localhost.js'
import configPlanfredLscGroupLu from '@/config/hosts/planfred.lsc360.lu.js'

const isBackupApp = import.meta.env.VITE_IS_BACKUP_APP === 'true'

const runtimeConfig = {
  isBackupApp,
  packageVersion: {
    client: {
      version: packageJson.version,
      buildDate: packageJson.buildDate,
    },
    latestClient: {
      version: null,
      buildDate: null,
    },
    server: {
      version: null,
      buildDate: null,
    },
  },
}

const getConfig = () => {
  const useLocalhostConfig = isOnLocalhost

  const localTestingHostname = useLocalhostConfig ? window.localStorage.getItem('planfred_hostname') : undefined
  let hostConfig = configLocalhostTest

  if (window.location.hostname.endsWith('app.planfred.com') || localTestingHostname === 'app.planfred.com') {
    hostConfig = configAppPlanfredCom
  } else if (window.location.hostname.endsWith('app.planfred.com.planfredapp.com')) {
    hostConfig = configAppPlanfredComPlanfredappCom
  } else if (window.location.hostname.endsWith('app.apg-planserver.at') || window.location.hostname.endsWith('app.apg-planserver.at.planfredapp.com') || localTestingHostname === 'app.apg-planserver.at') {
    hostConfig = configAppApgPlanserverAt
  } else if (window.location.hostname.endsWith('app.klg-planserver.de') || window.location.hostname.endsWith('app.klg-planserver.de.planfredapp.com') || localTestingHostname === 'app.klg-planserver.de') {
    hostConfig = configAppKlgPlanserverDe
  } else if (window.location.hostname.endsWith('app.koupserver.at') || window.location.hostname.endsWith('app.koupserver.at.planfredapp.com') || localTestingHostname === 'app.koupserver.at') {
    hostConfig = configAppKoupserverAt
  } else if (window.location.hostname.endsWith('app.egv-planserver.de') || window.location.hostname.endsWith('app.egv-planserver.de.planfredapp.com') || localTestingHostname === 'app.egv-planserver.de') {
    hostConfig = configAppEgvPlanserverDe
  } else if (window.location.hostname.endsWith('app.staabserver.com') || window.location.hostname.endsWith('app.staabserver.com.planfredapp.com') || localTestingHostname === 'app.staabserver.com') {
    hostConfig = configAppStaabserverCom
  } else if (window.location.hostname.endsWith('planfred.lsc-group.lu') || window.location.hostname.endsWith('planfred.lsc-group.lu.planfredapp.com') || localTestingHostname === 'planfred.lsc-group.lu') {
    hostConfig = configPlanfredLscGroupLu
  } else if (window.location.hostname.endsWith('planfred.lsc360.lu') || window.location.hostname.endsWith('planfred.lsc360.lu.planfredapp.com') || localTestingHostname === 'planfred.lsc360.lu') {
    hostConfig = configPlanfredLscGroupLu
  } else if (window.location.hostname.endsWith('edit.demo.planfred.com') || window.location.hostname.endsWith('edit.demo.planfred.com.planfredapp.com') || localTestingHostname === 'edit.demo.planfred.com') {
    hostConfig = configEditDemoPlanfredCom

    if (useLocalhostConfig) {
    // override with localhost testing data
      hostConfig.demoUserEmail = 'test@test.planfredapp.com'
      hostConfig.demoUserPassword = 'Welcome123'
      hostConfig.demoProjectID = '58b70a51e7b6b69c746e8df3'
      hostConfig.demoProjectIDEnglish = '22270a51e7b6b69c746e8222'
      hostConfig.msToShowDemoserverModal = 2 * 1000 // 2 sec
    }
  } else if (window.location.hostname.endsWith('demo.planfred.com') || window.location.hostname.endsWith('demo.planfred.com.planfredapp.com') || localTestingHostname === 'demo.planfred.com') {
    hostConfig = configDemoPlanfredCom

    if (useLocalhostConfig) {
    // override with localhost testing data
      hostConfig.demoUserEmail = 'test@test.planfredapp.com'
      hostConfig.demoUserPassword = 'Welcome123'
      hostConfig.demoProjectID = '58b70a51e7b6b69c746e8df3'
      hostConfig.demoProjectIDEnglish = '22270a51e7b6b69c746e8222'
      hostConfig.msToShowDemoserverModal = 2 * 1000 // 2 sec
    }
  } else if (localTestingHostname === 'backup.localhost' || window.location.protocol === 'file:') {
    hostConfig.api.host = `/${hostConfig.api.apiversion}`
    hostConfig.isBackupApp = true
  }

  if (useLocalhostConfig) {
    // port offsets based on 9000 default client port offset
    hostConfig.api.websocketServerUrl = `http://${window.location.hostname}:${parseInt(window.location.port) - 9000 + 3000}`
    hostConfig.awsS3BucketHost = `http://${window.location.hostname}:${parseInt(window.location.port) - 9000 + 9999}/planfredlocalhost` // Bucket name is only necessary for localhost. Other environments don't use s3 path style links
    hostConfig.api.host = `/${hostConfig.api.apiversion}`
  }

  return {
    ...runtimeConfig,
    ...hostConfig,
  }
}

export default {
  install: (app) => {
    const config = getConfig()

    app.config.globalProperties.$config = window.Cypress ? reactive(config) : config // We only need the reactive config for Cypress, so we can override the config
  },
}
